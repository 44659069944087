import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from './ThemeContext.js';
import { ReactComponent as SendIcon } from './send.svg';
import './SendButton.css';

const SendButton = ({ userInput, fontSize, onSvgReRender }) => {
    const { primaryColor, isDarkMode } = useContext(ThemeContext); // Removed zIndices
    const sendButtonRef = useRef(null);
    const [iconColor, setIconColor] = useState(isDarkMode ? '#f0f0f5' : '#f0f0f5');
    const backgroundColor = isDarkMode ? '#1E1E1E' : '#ffffff'; // Set background color based on theme '#2c2c2e' : '#f0f0f5'

    useEffect(() => {
        if (userInput.trim()) {
            setIconColor(primaryColor);
        } else {
            setIconColor(isDarkMode ? '#f0f0f5' : '#f0f0f5');
        }

        // Notify parent component (Chat) to re-render when SVG changes
        if (onSvgReRender) {
            onSvgReRender();
        }
    }, [userInput, primaryColor, isDarkMode, onSvgReRender]);

    return (
        <button 
            ref={sendButtonRef} 
            type="submit" 
            disabled={!userInput.trim()} 
            className="send-button"
            style={{ 
                position: 'absolute',  // Keep absolute positioning
                backgroundColor: 'transparent', // Set the button background to transparent
                padding: 0, 
                border: 'none', 
                borderRadius: '10%', // Optional: to match the button's shape
            }}
        >
            <SendIcon 
                key={iconColor} 
                style={{ 
                    color: iconColor, 
                    width: '100%', 
                    height: '90%',
                    backgroundColor: backgroundColor,
                    //borderRadius: '100%'
                }} 
            />
        </button>
    );
};

export default SendButton;
