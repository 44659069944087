import React, { useContext, useEffect, useRef, useState } from 'react';
import { ThemeContext } from './ThemeContext.js';
import './Options.css';
import flappyBirdIcon from './flappybird.png';
import { ReactComponent as ProfileIcon } from './profile.svg'; // Import profile.svg as a React component
import { ReactComponent as SettingsIcon } from './settings.svg'; // Import settings.svg as a React component
import Settings from './Settings';
import FlappyBird from './FlappyBird';

// Function to detect if the device is mobile
const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
};

// Helper function to calculate luminance
const getLuminance = (hex) => {
    const rgb = parseInt(hex.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const Options = ({
    toggleOptions,
    isOptionsOpen,
    chatButtonRealTop,
    chatButtonRealBottom,
    chatButtonRealLeft,
    chatButtonRealRight,
    chatButtonRealWidth,
    chatButtonRealHeight,
    chatRealBottom,
    chatRealRight,
    chatRealWidth,
    chatRealHeight,
    updateOptionsDimensions,
    buttonRef,
}) => {
    const {
        isDarkMode,
        primaryColor,
        zIndices,
        isSettingsOpen,
        toggleSettingsOpen,
        isFlappyBirdOpen,
        toggleFlappyBirdOpen
    } = useContext(ThemeContext);

    const optionsRef = useRef(null);
    const settingsButtonRef = useRef(null); // Added ref for settings button

    const [OptionsRealTop, setOptionsRealTop] = useState(0);
    const [OptionsRealLeft, setOptionsRealLeft] = useState(0);
    const [OptionsRealBottom, setOptionsRealBottom] = useState(0);
    const [OptionsRealRight, setOptionsRealRight] = useState(0);
    const [OptionsRealWidth, setOptionsRealWidth] = useState(0);
    const [OptionsRealHeight, setOptionsRealHeight] = useState(0);

    const updateOptionsDimensionsOnResize = () => {
        if (optionsRef.current && isOptionsOpen) {
            const rect = optionsRef.current.getBoundingClientRect();
            const viewportHeight = window.innerHeight;

            const calculatedOptionsRealHeight = rect.height;
            const calculatedOptionsRealBottom = viewportHeight - rect.bottom;
            const calculatedOptionsRealTop = viewportHeight - rect.top;
            const calculatedOptionsRealLeft = rect.left;
            const calculatedOptionsRealRight = chatButtonRealRight;
            const calculatedOptionsRealWidth = rect.width;

            if (updateOptionsDimensions) {
                updateOptionsDimensions({
                    top: calculatedOptionsRealTop,
                    left: calculatedOptionsRealLeft,
                    bottom: calculatedOptionsRealBottom,
                    right: calculatedOptionsRealRight,
                    width: calculatedOptionsRealWidth,
                    height: calculatedOptionsRealHeight,
                });
            }
        }
    };

    useEffect(() => {
        updateOptionsDimensionsOnResize();
    }, [isOptionsOpen, zIndices.options]);

    useEffect(() => {
        const handleResize = () => {
            updateOptionsDimensionsOnResize();

            // Close settings if open on viewport resize
            if (isSettingsOpen) {
                toggleSettingsOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [chatButtonRealRight, isSettingsOpen]); // Added `isSettingsOpen` to dependencies

    const isMobile = isMobileDevice(); // Determine if the device is mobile

    // Calculate text color based on luminance
    const luminance = getLuminance(primaryColor);
    const textColor = isDarkMode
        ? luminance > 128 ? '#1E1E1E' : '#f0f0f5'
        : luminance > 128 ? '#1E1E1E' : '#f0f0f5';

    const optionsStyle = {
        display: isOptionsOpen ? 'flex' : 'none',
        flexDirection: 'row',
        position: 'fixed',
        //top: !isMobile ? '3.5vh' : 'unset',    // Use 'top' for desktop
        top: isMobile ? 'unset' : '6vh', 
        bottom: isMobile ? '78vh' : 'unset',
        left: 'calc(13.5vmax + 17vmin)',
        height: '10vh',
        overflowX: 'auto',
        overflowY: 'hidden',
        zIndex: zIndices.options,
        transition: 'transform 0.3s ease, width 0.3s ease',
        //borderRadius: '0px 0px 4vh 4vh',
    };

    const handleOptionsButtonClick = (buttonType) => {
        if (buttonType === 'settings') toggleSettingsOpen();
        if (buttonType === 'flappyBird') toggleFlappyBirdOpen();
    };

    const handleChatButtonClick = () => {
        if (isSettingsOpen || isFlappyBirdOpen) {
            toggleSettingsOpen(false);
            toggleFlappyBirdOpen(false);
        }
    };

    return (
        <>
            <div
                className={`options-container ${isDarkMode ? 'dark-mode' : 'light-mode'} ${isOptionsOpen ? 'active' : ''}`}
                style={optionsStyle}
                ref={optionsRef}
            >
                <button
                    onClick={handleChatButtonClick}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 1,
                        color: textColor,
                    }}
                >
                    <ProfileIcon
                        style={{
                            width: '4.5vmin',
                            height: '4.5vmin',
                            fill: 'currentColor',
                            color: textColor,
                        }}
                    />
                    <span></span>
                </button>

                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        handleOptionsButtonClick('settings');
                    }}
                    ref={settingsButtonRef}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 1,
                        color: textColor,
                    }}
                >
                    <SettingsIcon
                        style={{
                            width: '4vmin',
                            height: '4vmin',
                            fill: 'currentColor',
                            color: textColor,
                        }}
                    />
                    <span></span>
                </button>

                <button
                    onClick={() => handleOptionsButtonClick('flappyBird')}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        zIndex: 1,
                        color: textColor,
                    }}
                >
                    <img
                        src={flappyBirdIcon}
                        alt="Flappy Bird"
                        style={{ width: '4vmin', height: '4vmin' }}
                    />
                    <span></span>
                </button>
            </div>

            {isSettingsOpen && (
                <Settings
                    settingsButtonRef={settingsButtonRef}
                    distance={{
                        bottom: OptionsRealBottom,
                        right: OptionsRealRight,
                        width: OptionsRealWidth,
                        height: OptionsRealHeight,
                    }}
                    ChatBottom={chatButtonRealBottom}
                    chatRealBottom={chatRealBottom}
                    chatRealRight={chatRealRight}
                    chatRealWidth={chatRealWidth}
                    chatRealHeight={chatRealHeight}
                />
            )}
            {isFlappyBirdOpen && (
                <FlappyBird
                    onClose={() => handleOptionsButtonClick('flappyBird')}
                    distance={{
                        bottom: OptionsRealBottom,
                        right: OptionsRealRight,
                        width: OptionsRealWidth,
                        height: OptionsRealHeight,
                    }}
                    ChatBottom={chatButtonRealBottom}
                    chatRealBottom={chatRealBottom}
                    chatRealRight={chatRealRight}
                    chatRealWidth={chatRealWidth}
                    chatRealHeight={chatRealHeight}
                />
            )}
        </>
    );
};

export default Options;
