import React, { useState, useEffect, useRef, useContext } from 'react';
import { ThemeContext } from './ThemeContext.js';
import { ReactComponent as NewIcon } from './new.svg'; // Import new.svg for "New Chat"
import { ReactComponent as ReviewIcon } from './review.svg'; // Import review.svg for "Review"
import { ReactComponent as FeedbackIcon } from './feedback.svg'; // Import feedback.svg for "Feedback"
import { ReactComponent as ReportIcon } from './report.svg'; // Import report.svg for "Report"
import { ReactComponent as AthenaIcon } from './athena.svg'; // Import athena.svg for "Athena"
import { ReactComponent as SirenIcon } from './siren.svg'; // Import siren.svg for "Siren"
import { ReactComponent as Logo } from './logo.svg'; // Default fallback logo

const getBackgroundColor = (primaryColor, isDarkMode) => {
  if (primaryColor === '#007bff') {
    return isDarkMode ? '#555555' : '#000000';
  }
  return primaryColor;
};

const adjustTextColor = (primaryColor) => {
  const luminance = getLuminance(primaryColor);
  return luminance > 128 ? '#3c3c3e' : '#ffffff';
};

const getLuminance = (hex) => {
  const rgb = parseInt(hex.slice(1), 16);
  const r = (rgb >> 16) & 255;
  const g = (rgb >> 8) & 255;
  const b = rgb & 255;
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

const Down = ({ isOpen: initialIsOpen, chatRef, buttonRef }) => {
  const { isOptionsOpen, isDarkMode, primaryColor } = useContext(ThemeContext);
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [position, setPosition] = useState({ left: 0, top: 0 });
  const downRef = useRef(null);

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const backgroundColor = getBackgroundColor(primaryColor, isDarkMode);
  const textColor = adjustTextColor(primaryColor);

  const updatePosition = () => {
    if (buttonRef?.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      setPosition({
        left: buttonRect.left,
        top: buttonRect.bottom,
      });
    }
  };

  const handleClickOutside = (event) => {
    if (downRef.current && !downRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
      setIsOpen(false); // Close when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    updatePosition();
    window.addEventListener('resize', updatePosition);
    return () => window.removeEventListener('resize', updatePosition);
  }, [buttonRef, chatRef, isOpen, isOptionsOpen]);

  useEffect(() => {
    setIsOpen(initialIsOpen); // Sync local isOpen state with the parent prop
  }, [initialIsOpen]);

  const buttons = [
    { label: 'New Chat' },
    { label: 'Review' },
    { label: 'Feedback' },
    { label: 'Report' },
    { label: 'Athena' },
    { label: 'Siren' },
  ];

  return isOpen ? (
    <div
      className="down-container"
      ref={downRef}
      style={{
        position: 'fixed',
        top: '7.55vh' ,//'1.6vmin',//'1.5vmin',
        left: `calc(1.75vmax + 8vmin)`,
        //top: position.top,
        //right: position.left,
        width: 'auto',
        height: 'auto',
        zIndex: 1003,
        borderRadius: isMobile ? '0' : '2vh',
        overflow: 'hidden',
        backgroundColor: backgroundColor,
        color: textColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'top',
        padding: '2vh',
        //zIndex: zIndices.chat + 2,
      }}
    >
      {buttons.map((button, index) => (
        <button
          key={index}
          className="down-button"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: backgroundColor,
            color: textColor,
            border: 'none',
            padding: '1.5vh 2.5vh',
            marginBottom: index < buttons.length - 1 ? '1vh' : '0',
            borderRadius: '1vh',
            cursor: 'pointer',
            width: '100%',
          }}
        >
          {button.label === 'New Chat' ? (
            <NewIcon style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          ) : button.label === 'Review' ? (
            <ReviewIcon style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          ) : button.label === 'Feedback' ? (
            <FeedbackIcon style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          ) : button.label === 'Report' ? (
            <ReportIcon style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          ) : button.label === 'Athena' ? (
            <AthenaIcon style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          ) : button.label === 'Siren' ? (
            <SirenIcon style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          ) : (
            <Logo style={{ width: '2vh', height: '2vh', marginRight: '1vh', flexShrink: 0 }} />
          )}
          <span style={{ marginLeft: '0', textAlign: 'left' }}>{button.label}</span>
        </button>
      ))}
    </div>
  ) : null;
};

export default Down;
