// src/LottieAnimation.js

import React from 'react';
import Lottie from 'lottie-react';
import dotAnimation from './dot.json'; // Ensure dot.json is in the src directory

const LottieAnimation = () => {
  const defaultOptions = {
    animationData: dotAnimation,
    loop: true,
    autoplay: true,
    // You can adjust the rendererSettings if needed
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div style={{ width: '50px', height: '50px' }}>
      <Lottie {...defaultOptions} />
    </div>
  );
};

export default LottieAnimation;
