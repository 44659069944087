const API_URL = process.env.REACT_APP_API_URL || 'https://athena.dropdept.com/api/gpt/generate';

/**
 * Fetches the client's public IP address using ipify API.
 * @returns {Promise<string>} The client's IP address.
 */
const fetchClientIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    if (!response.ok) throw new Error('Failed to fetch IP address');
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error('Error fetching client IP:', error);
    return 'Unknown';
  }
};

/**
 * Sends a message along with the client's IP and session token to the backend GPTController.
 * @param {string} message - The user's message.
 * @param {string} sessionToken - The session token from ThemeContext (passed from Chat component).
 * @returns {Promise<string>} The bot's response.
 */
export const fetchBotResponse = async (message, sessionToken) => {
  try {
    // Log the session token for debugging
    console.log('BotService.js: Session token:', sessionToken);

    // Check if sessionToken is available
    if (!sessionToken) {
      console.error('BotService.js: Session token is not available yet. Aborting request.');
      throw new Error('Session token is missing.');
    }

    // Fetch the client IP
    const ip = await fetchClientIP();

    // Log the IP address and message for debugging
    console.log('BotService.js: IP Address:', ip);
    console.log('BotService.js: Message to bot:', message);

    // Make the API request with the message, IP, and session token
    const response = await fetch(API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ message, ip, sessionToken }), // Include the sessionToken
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`BotService.js: Server responded with status ${response.status}: ${errorText}`);
      throw new Error(`Server responded with status ${response.status}: ${errorText}`);
    }

    const data = await response.json();

    // Log the bot response for debugging
    console.log('BotService.js: Bot response:', data.response);

    return data.response;
  } catch (error) {
    console.error('BotService.js: Failed to fetch the bot response:', error);
    throw error;
  }
};
