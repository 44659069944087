// ThemeContext.js

import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const initialColor = '#007bff'; // Default color (blue)

  // Retrieve persisted states from localStorage or set default values
  const [isDarkMode, setIsDarkMode] = useState(() => JSON.parse(localStorage.getItem('isDarkMode')) || false);
  const [primaryColor, setPrimaryColor] = useState(() => localStorage.getItem('primaryColor') || initialColor);
  const [isUserToggled, setIsUserToggled] = useState(false); // Track if the user manually changed the theme

  // State to manage visibility and settings
  const [isOptionsOpen, setIsOptionsOpen] = useState(() => JSON.parse(localStorage.getItem('isOptionsOpen')) || false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(() => JSON.parse(localStorage.getItem('isSettingsOpen')) || false);
  const [isFlappyBirdOpen, setIsFlappyBirdOpen] = useState(() => JSON.parse(localStorage.getItem('isFlappyBirdOpen')) || false);

  const [isChatButtonClicked, setIsChatButtonClicked] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(() => JSON.parse(localStorage.getItem('isChatOpen')) || false);
  const [isMobileHeaderOpen, setIsMobileHeaderOpen] = useState(() => JSON.parse(localStorage.getItem('isMobileHeaderOpen')) || false);

  const [chatButtonMetrics, setChatButtonMetrics] = useState({
    chatButtonRealTop: 0,
    chatButtonRealBottom: 20,
    chatButtonRealLeft: 0,
    chatButtonRealRight: 20,
    chatButtonRealWidth: 0,
    chatButtonRealHeight: 0,
  });

  const [chatMetrics, setChatMetrics] = useState({
    chatRealBottom: 0,
    chatRealRight: 0,
    chatRealWidth: 0,
    chatRealHeight: 0,
  });

  const [chatBottomPosition, setChatBottomPosition] = useState('0px');

  const zIndices = {
    chat: 1000,
    options: 1001,
    settings: 1002,
    flappyBird: 1003,
    closeFlappyBird: 1004,
  };

  const [isChatButtonVisible, setChatButtonVisible] = useState(() => JSON.parse(localStorage.getItem('isChatButtonVisible')) || true);

  // New state to store the session token
  const [sessionToken, setSessionToken] = useState(null);

  // Helper function to log and send postMessage
  const logAndPostMessage = (message) => {
    console.log('Sending postMessage:', message); // Log message being passed
    window.parent.postMessage(message, '*');      // Send postMessage to parent window
  };

  // Persist state changes to localStorage
  useEffect(() => {
    localStorage.setItem('isDarkMode', JSON.stringify(isDarkMode));
  }, [isDarkMode]);

  useEffect(() => {
    localStorage.setItem('primaryColor', primaryColor);
  }, [primaryColor]);

  useEffect(() => {
    localStorage.setItem('isOptionsOpen', JSON.stringify(isOptionsOpen));
  }, [isOptionsOpen]);

  useEffect(() => {
    localStorage.setItem('isChatOpen', JSON.stringify(isChatOpen));
  }, [isChatOpen]);

  useEffect(() => {
    localStorage.setItem('isChatButtonVisible', JSON.stringify(isChatButtonVisible));
  }, [isChatButtonVisible]);

  useEffect(() => {
    localStorage.setItem('isSettingsOpen', JSON.stringify(isSettingsOpen));
  }, [isSettingsOpen]);

  useEffect(() => {
    localStorage.setItem('isFlappyBirdOpen', JSON.stringify(isFlappyBirdOpen));
  }, [isFlappyBirdOpen]);

  // Auto-switch theme at 6 AM and 6 PM
  useEffect(() => {
    const checkTimeAndUpdateTheme = () => {
      const now = new Date();
      const hour = now.getHours();

      if (!isUserToggled) { // Only auto-update if the user has not manually toggled
        if (hour === 6 && isDarkMode) {
          setIsDarkMode(false); // Switch to light mode at 6 AM
          logAndPostMessage({ isDarkMode: false });
          console.log('Switched to light mode at 6 AM');
        } else if (hour === 18 && !isDarkMode) {
          setIsDarkMode(true); // Switch to dark mode at 6 PM
          logAndPostMessage({ isDarkMode: true });
          console.log('Switched to dark mode at 6 PM');
        }
      }
    };

    checkTimeAndUpdateTheme(); // Initial check

    const intervalId = setInterval(checkTimeAndUpdateTheme, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup interval on unmount
  }, [isDarkMode, isUserToggled]);

  // Inject styles into the document
  useEffect(() => {
    const style = document.createElement('style');
    const backgroundColor = isOptionsOpen && isChatOpen ? 'transparent' : isDarkMode ? '#000000' : '#ffffff';
    const boxShadow = isOptionsOpen && isChatOpen ? 'none' : '0 8px 20px rgba(0, 0, 0, 0.2)';

    style.innerHTML = `
      .chat-button {
        position: fixed;
        bottom: 20px;
        right: 20px;
        border: none;
        cursor: pointer;
        z-index: 1010;
        box-shadow: ${boxShadow};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: background-color 0.3s ease, transform 0.3s ease, width 0.3s ease;
        background-color: ${backgroundColor};
      }
      .chat-button.light-mode {
        background-color: #ffffff;
        color: #333;
      }
      .chat-button.light-mode:hover {
        background-color: ${isOptionsOpen ? '#ffffff' : 'rgba(0, 0, 0, 0.1)'};
      }
      .chat-button.dark-mode {
        background-color: #000000;
        color: #ffffff;
      }
      .chat-button.dark-mode:hover {
        background-color: ${isOptionsOpen ? '#000000' : '#2c2c2e'};
      }
      .chat-button-logo {
        width: 80%;
        height: 80%;
        object-fit: contain;
        border-radius: inherit;
      }
      .chat-button.expanded {
        width: 100%;
        border-radius: 10px;
        right: 0;
        bottom: 0;
      }
    `;
    document.head.appendChild(style);

    return () => {
      document.head.removeChild(style);
    };
  }, [isDarkMode, primaryColor, isOptionsOpen, isChatOpen]);

  // Compute chat bottom position
  useEffect(() => {
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    const bottomPosition = isMobile
      ? '10vh'
      : `${chatButtonMetrics.chatButtonRealTop}px`;

    setChatBottomPosition(bottomPosition);
    console.log('Computed Chat Bottom Position:', bottomPosition);
  }, [isOptionsOpen, chatButtonMetrics]);

  const toggleTheme = () => {
    setIsDarkMode((prevMode) => !prevMode);
    setIsUserToggled(true); // User has manually toggled the theme
  };

  // Update primary color and trigger effects
  const updatePrimaryColor = (newColor) => {
    setPrimaryColor(newColor);
    logAndPostMessage({ primaryColor: newColor });
  };

  // Reset primary color to its initial state
  const resetPrimaryColor = () => {
    setPrimaryColor(initialColor);
    logAndPostMessage({ primaryColor: initialColor });
  };

  const toggleChatButtonVisibility = () => {
    setChatButtonVisible((prevVisible) => !prevVisible);
    logAndPostMessage({ isChatButtonVisible: !isChatButtonVisible });
  };

  // Function to toggle the Options open state
  const toggleOptionsOpen = () => {
    if (!isChatOpen) return;

    setIsOptionsOpen((prevOpen) => {
      const newIsOpen = !prevOpen;
      logAndPostMessage({ isOptionsOpen: newIsOpen });

      if (newIsOpen) {
        document.documentElement.style.setProperty('--chat-button-background', 'transparent');
        document.documentElement.style.setProperty('--chat-button-box-shadow', 'none');
        console.log('Options opened: Background set to transparent, box-shadow removed');
      } else {
        const backgroundColor = isDarkMode ? '#000000' : primaryColor;
        document.documentElement.style.setProperty('--chat-button-background', backgroundColor);
        document.documentElement.style.setProperty('--chat-button-box-shadow', '0 8px 20px rgba(0, 0, 0, 0.2)');
        console.log(`Options closed: Background set to ${backgroundColor}, box-shadow applied`);
      }

      return newIsOpen;
    });
  };

  // Toggle Settings and FlappyBird states
  const toggleSettingsOpen = () => {
    setIsSettingsOpen((prevOpen) => !prevOpen);
    logAndPostMessage({ isSettingsOpen: !isSettingsOpen });
  };

  const toggleFlappyBirdOpen = () => {
    setIsFlappyBirdOpen((prevOpen) => !prevOpen);
    logAndPostMessage({ isFlappyBirdOpen: !isFlappyBirdOpen });
  };

  // Update chat button metrics
  const updateChatButtonMetrics = (metrics) => {
    console.log('Sending updated chat button metrics:', metrics);
    setChatButtonMetrics(metrics);
    logAndPostMessage({ chatButtonMetrics: metrics });
  };

  // Update chat metrics only if the chat button is clicked
  const updateChatMetrics = (metrics) => {
    if (isChatButtonClicked) {
      console.log('Sending updated chat metrics:', metrics);
      setChatMetrics(metrics);
      logAndPostMessage({ chatMetrics: metrics });
    } else {
      console.log('ChatButton not clicked yet, skipping chat metrics update.');
    }
  };

  // Handle ChatButton click
  const handleChatButtonClick = () => {
    setIsChatButtonClicked(true);
    setIsChatOpen((prev) => {
      const newIsChatOpen = !prev;
      logAndPostMessage({ isChatOpen: newIsChatOpen });
      return newIsChatOpen;
    });
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);
    if (isMobile) {
      setIsMobileHeaderOpen((prev) => !prev);
    }
  };

  // Log chat button metrics updates
  useEffect(() => {
    console.log('Received updated chat button metrics:', chatButtonMetrics);
    logAndPostMessage({ chatButtonMetrics });
  }, [chatButtonMetrics]);

  // Log chat metrics updates
  useEffect(() => {
    console.log('Received updated chat metrics:', chatMetrics);
    logAndPostMessage({ chatMetrics });
  }, [chatMetrics]);

  // Log and send postMessage on state changes
  useEffect(() => {
    console.log(`isOptionsOpen state updated in ThemeContext: ${isOptionsOpen}`);
    logAndPostMessage({ isOptionsOpen });
  }, [isOptionsOpen]);

  useEffect(() => {
    console.log(`isDarkMode state updated in ThemeContext: ${isDarkMode}`);
    logAndPostMessage({ isDarkMode });
  }, [isDarkMode]);

  useEffect(() => {
    console.log(`primaryColor state updated in ThemeContext: ${primaryColor}`);
    logAndPostMessage({ primaryColor });
  }, [primaryColor]);

  useEffect(() => {
    console.log(`isChatOpen state updated in ThemeContext: ${isChatOpen}`);
    logAndPostMessage({ isChatOpen });
  }, [isChatOpen]);

  // Listen for messages from the parent window
  useEffect(() => {
    const handleParentMessage = (event) => {
      const allowedOrigins = ['https://dropdept.com', 'https://thriving-haupia-829bfb.netlify.app']; // Add your origins here
      console.log(`Received message from origin: ${event.origin}`, event.data);
      if (!allowedOrigins.includes(event.origin)) {
        console.warn(`Received message from unauthorized origin: ${event.origin}`);
        return;
      }

      const { isOptionsOpen, isChatOpen, primaryColor, isDarkMode, type, sessionToken: receivedToken } = event.data;

      if (type === 'SESSION_TOKEN' && receivedToken) {
        console.log('Received session token via postMessage:', receivedToken);
        setSessionToken(receivedToken);
        // Optionally, send a confirmation back
        window.parent.postMessage({
          type: 'SESSION_TOKEN_RECEIVED',
          message: 'Session token received by React app.',
        }, event.origin);
      }

      if (typeof isOptionsOpen === 'boolean') {
        setIsOptionsOpen(isOptionsOpen);
        console.log('isOptionsOpen state updated from parent:', isOptionsOpen);
      }

      if (typeof isChatOpen === 'boolean') {
        setIsChatOpen(isChatOpen);
        console.log('isChatOpen state updated from parent:', isChatOpen);
      }

      if (typeof isDarkMode === 'boolean') {
        setIsDarkMode(isDarkMode);
        console.log('isDarkMode state updated from parent:', isDarkMode);
      }

      if (primaryColor) {
        setPrimaryColor(primaryColor);
        console.log('primaryColor updated from parent:', primaryColor);
      }
    };

    window.addEventListener('message', handleParentMessage);

    return () => window.removeEventListener('message', handleParentMessage);
  }, []);

  // In-depth logging for sessionToken state changes
  useEffect(() => {
    if (sessionToken) {
      console.log('Session token has been set:', sessionToken);
      // Store sessionToken in a cookie
      document.cookie = `sessionToken=${sessionToken}; path=/;`;
    } else {
      console.log('Session token is currently null or undefined.');
      // Optionally, delete the cookie
      document.cookie = `sessionToken=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
    }
  }, [sessionToken]);

  // Notify parent window that React app is ready to receive messages
  useEffect(() => {
    console.log('React app is ready. Sending READY_FOR_SESSION_TOKEN message to parent.');
    window.parent.postMessage({ type: 'READY_FOR_SESSION_TOKEN' }, '*');
  }, []);

  return (
    <ThemeContext.Provider value={{
      isDarkMode,
      toggleTheme,
      primaryColor,
      updatePrimaryColor,
      resetPrimaryColor,
      zIndices,
      isChatButtonVisible,
      toggleChatButtonVisibility,
      isOptionsOpen,
      toggleOptionsOpen,
      isSettingsOpen,
      toggleSettingsOpen,
      isFlappyBirdOpen,
      toggleFlappyBirdOpen,
      setChatButtonMetrics: updateChatButtonMetrics,
      setChatMetrics: updateChatMetrics,
      chatButtonMetrics,
      chatMetrics,
      handleChatButtonClick,
      isChatOpen,
      setIsChatOpen,
      chatBottomPosition,
      isMobileHeaderOpen,
      setIsMobileHeaderOpen,
      sessionToken, // Expose sessionToken in context
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
