import React, { useState, useEffect, useRef, useContext } from 'react';
import './Chat.css'; // Your custom styles
import Options from './Options.js';
import SendButton from './SendButton.js';
import { ThemeContext } from './ThemeContext.js';
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import { ReactComponent as DownIcon } from './down.svg';
import { ReactComponent as ThumbUpIcon } from './ThumbUp.svg';
import { ReactComponent as ThumbDownIcon } from './ThumbDown.svg';
import { ReactComponent as RefreshIcon } from './refresh.svg';
import anime from 'animejs/lib/anime.es.js'; // Import anime.js
import { ReactComponent as Logo } from './logo.svg';
import { ReactComponent as EditIcon } from './edit.svg';
import Slider from 'react-slick'; // Import react-slick
import Down from './Down'; // Import the Down component
import { PromptsList } from './prompts.js'; // Import the PromptsList from prompts.js

// Import slick-carousel styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { adjustTextColor, getBackgroundColor, isMobileDevice, getWaveColor } from './utils.js';

// Import the AthenaAnimation component
import AthenaAnimation from './AthenaAnimation.js'; // Ensure the path is correct

// Import the fetchBotResponse function from BotService.js
import { fetchBotResponse } from './BotService.js';

// Import the LottieAnimation component
import LottieAnimation from './LottieAnimation.js'; // Ensure the path is correct

const Chat = ({
  botMessageColor,
  isChatOpen,
  chatButtonRealTop = 0,
  chatButtonRealBottom = 0,
  chatButtonRealLeft = 0,
  chatButtonRealRight = 0,
  chatButtonRealWidth = 0,
  chatButtonRealHeight = 0,
}) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [chatWidth, setChatWidth] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [latestUserMessageIndex, setLatestUserMessageIndex] = useState(null);
  const [latestBotMessageIndex, setLatestBotMessageIndex] = useState(null);
  const [originalMessage, setOriginalMessage] = useState('');
  const messageRefs = useRef([]);
  const chatWindowRef = useRef(null);
  const chatRef = useRef(null);
  const chatButtonRef = useRef(null);
  const textareaRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0); // State to track current slide

  const [isDownOpen, setIsDownOpen] = useState(false);

  const [isMultiline, setIsMultiline] = useState(false); // New state to track multiline messages
  const checkIfMultiline = (text) => {
    const isMultilineMessage = text.includes('\n');
    setIsMultiline(isMultilineMessage);
  };

  // Destructure sessionToken from ThemeContext
  const {
    isDarkMode,
    primaryColor,
    zIndices,
    isOptionsOpen,
    toggleOptionsOpen,
    setIsChatOpen,
    sessionToken, // Added sessionToken
  } = useContext(ThemeContext);

  const textColor = adjustTextColor(primaryColor);
  const backgroundColor = getBackgroundColor(primaryColor, isDarkMode);
  const waveColor = getWaveColor(backgroundColor);

  const getLuminance = (hex) => {
    const rgb = parseInt(hex.slice(1), 16);
    const r = (rgb >> 16) & 255;
    const g = (rgb >> 8) & 255;
    const b = rgb & 255;
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const luminance = getLuminance(primaryColor);
  const logoColor = isDarkMode
    ? luminance > 128
      ? '#1E1E1E'
      : '#f0f0f5'
    : luminance > 128
    ? '#1E1E1E'
    : '#f0f0f5';

  const [chatPosition, setChatPosition] = useState({ bottom: 100, right: 20 });
  const [optionsDimensions, setOptionsDimensions] = useState({});

  const [storedChatButtonTop, setStoredChatButtonTop] = useState(chatButtonRealTop);
  const [storedChatButtonBottom, setStoredChatButtonBottom] = useState(chatButtonRealBottom);
  const [storedChatButtonLeft, setStoredChatButtonLeft] = useState(chatButtonRealLeft);
  const [storedChatButtonRight, setStoredChatButtonRight] = useState(chatButtonRealRight);
  const [storedChatButtonWidth, setStoredChatButtonWidth] = useState(chatButtonRealWidth);
  const [storedChatButtonHeight, setStoredChatButtonHeight] = useState(chatButtonRealHeight);

  const [chatRealBottom, setChatRealBottom] = useState(0);
  const [chatRealRight, setChatRealRight] = useState(0);
  const [chatRealWidth, setChatRealWidth] = useState(0);
  const [chatRealHeight, setChatRealHeight] = useState(0);

  const isMobile = isMobileDevice();
  const fontSize = 12;

  // Reference to manage word reveal intervals
  const wordRevealIntervals = useRef({});

  // Reference for Athena's animation
  const athenaRef = useRef(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [messages]);

  // Log sessionToken updates
  useEffect(() => {
    console.log('Chat.js: sessionToken updated:', sessionToken);
  }, [sessionToken]);

  // Update chat position on open/close
  useEffect(() => {
    const updateChatPosition = () => {
      if (chatRef.current && chatButtonRef.current) {
        const chatRect = chatRef.current.getBoundingClientRect();
        const chatButtonRect = chatButtonRef.current.getBoundingClientRect();

        setChatWidth(chatRect.width);

        const newChatPosition = {
          bottom: window.innerHeight - chatRect.bottom,
          right: window.innerWidth - chatRect.right,
        };
        setChatPosition(newChatPosition);
      }
    };

    updateChatPosition();
  }, [isChatOpen, isOptionsOpen]);

  // Handle Shopify resize messages
  useEffect(() => {
    const handleResizeShopify = () => {
      const message = isOptionsOpen
        ? { type: 'chat-resize', width: 'calc(45vw + 5vh)' } // Push Shopify content
        : { type: 'chat-resize', width: '0' }; // Reset Shopify content

      window.parent.postMessage(message, '*');
    };

    handleResizeShopify(); // Call initially when chat is opened/closed
  }, [isOptionsOpen]);

  // Listen for messages from the chat widget
  useEffect(() => {
    const handleShopifyResize = (event) => {
      if (event.data.type === 'chat-resize') {
        const mainContent = document.querySelector('.main-content');
        if (mainContent) {
          mainContent.style.marginRight = event.data.width; // Adjust Shopify store content width
        }
      }
    };

    window.addEventListener('message', handleShopifyResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleShopifyResize);
    };
  }, []);

  // Update stored chat button positions
  useEffect(() => {
    setStoredChatButtonTop(chatButtonRealTop);
    setStoredChatButtonBottom(chatButtonRealBottom);
    setStoredChatButtonLeft(chatButtonRealLeft);
    setStoredChatButtonRight(chatButtonRealRight);
    setStoredChatButtonWidth(chatButtonRealWidth);
    setStoredChatButtonHeight(chatButtonRealHeight);
  }, [
    chatButtonRealTop,
    chatButtonRealBottom,
    chatButtonRealLeft,
    chatButtonRealRight,
    chatButtonRealWidth,
    chatButtonRealHeight,
  ]);

  const handleUpdateOptionsDimensions = (dimensions) => {
    setOptionsDimensions(dimensions);
  };

  // Update chat real dimensions
  useEffect(() => {
    if (chatRef.current) {
      const chatRect = chatRef.current.getBoundingClientRect();
      const calculatedChatRealBottom = isOptionsOpen
        ? chatButtonRealTop + chatButtonRealBottom
        : chatButtonRealTop + chatButtonRealBottom;

      setChatRealBottom(calculatedChatRealBottom);
      setChatRealRight(storedChatButtonRight);
      setChatRealWidth(chatRect.width);
      setChatRealHeight(chatRect.height);
    }
  }, [isOptionsOpen, optionsDimensions, chatButtonRealTop, chatButtonRealBottom, storedChatButtonRight]);

  // Animate user messages when primary color changes
  useEffect(() => {
    messages.forEach((msg, index) => {
      if (msg.sender === 'user' && messageRefs.current[index]) {
        const messageDiv = messageRefs.current[index];
        anime({
          targets: messageDiv,
          backgroundColor: primaryColor,
          duration: 500,
          easing: 'easeOutQuad',
        });
      }
    });
  }, [primaryColor, messages]);

  // Helper function to reveal words one by one with dynamic speed
  const revealWords = (messageIndex, fullText) => {
    // Skip animations if the message is loading
    if (fullText === 'loading') return;

    const words = fullText.split(' ');
    const totalWords = words.length;
    const maxTotalTime = 5000; // 5 seconds in milliseconds
    let intervalTime = 250; // Interval per word in ms

    // Calculate total time with default interval
    const totalTimeWithDefault = totalWords * intervalTime;

    if (totalTimeWithDefault > maxTotalTime) {
      // Adjust interval time to fit within maxTotalTime
      intervalTime = maxTotalTime / totalWords;
    }

    let currentIndex = 0;

    // Set the isRevealing flag to true when revealing starts
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages];
      newMessages[messageIndex].isRevealing = true; // Track reveal progress
      return newMessages;
    });

    wordRevealIntervals.current[messageIndex] = setInterval(() => {
      currentIndex++;
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        if (newMessages[messageIndex].text === 'loading') {
          newMessages[messageIndex].text = ''; // Clear the loading text
        }
        newMessages[messageIndex].text = words.slice(0, currentIndex).join(' ');
        return newMessages;
      });

      if (currentIndex >= totalWords) {
        clearInterval(wordRevealIntervals.current[messageIndex]);
        delete wordRevealIntervals.current[messageIndex];

        // Set the isRevealing flag to false when reveal is done
        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[messageIndex].isRevealing = false;
          return newMessages;
        });
      }
    }, intervalTime); // Use dynamic interval time
  };

  // Function to send a message with loading animation
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    // Check if sessionToken is available before proceeding
    if (!sessionToken) {
      console.warn('Chat.js: sessionToken is not available, cannot send message.');
      // Optionally, inform the user or implement a retry mechanism here
      return;
    }

    const userMessage = { sender: 'user', text: userInput };
    const loadingMessage = { sender: 'bot', text: 'loading', isRevealing: false };

    const newMessages = [...messages, userMessage, loadingMessage];
    setMessages(newMessages);

    const newLatestUserMessageIndex = newMessages.length - 2;
    const newLatestBotMessageIndex = newMessages.length - 1;

    setLatestUserMessageIndex(newLatestUserMessageIndex);
    setLatestBotMessageIndex(newLatestBotMessageIndex);

    setUserInput('');

    // Check if the submitted message is multiline
    checkIfMultiline(userMessage.text);

    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.width = '100%';
    }

    // Log before making the API call
    console.log('Chat.js: Making API call with sessionToken:', sessionToken, 'and message:', userInput);

    try {
      const botResponseText = await fetchBotResponse(userInput, sessionToken); // Pass sessionToken

      // Start revealing words one by one with dynamic speed
      revealWords(newLatestBotMessageIndex, botResponseText);
    } catch (error) {
      console.error('Failed to fetch the bot response:', error);
      // Optionally, display an error message to the user
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        // Replace the loading message with the error message
        newMessages[newLatestBotMessageIndex] = {
          sender: 'bot',
          text: 'Sorry, something went wrong. Please try again.',
          isRevealing: false,
        };
        return newMessages;
      });
    }
  };

  // Handle message refresh with loading animation
  const handleRefresh = async () => {
    if (latestUserMessageIndex === null || latestBotMessageIndex === null) return;

    const lastUserMessage = messages[latestUserMessageIndex].text;

    // Check if sessionToken is available before proceeding
    if (!sessionToken) {
      console.warn('Chat.js: sessionToken is not available, cannot refresh message.');
      // Optionally, inform the user or implement a retry mechanism here
      return;
    }

    const newMessages = messages.slice(0, latestBotMessageIndex);
    const loadingMessage = { sender: 'bot', text: 'loading', isRevealing: false };
    newMessages.push(loadingMessage);
    setMessages(newMessages);

    const newLatestBotMessageIndex = newMessages.length - 1;
    setLatestBotMessageIndex(newLatestBotMessageIndex);

    // Log before making the API call
    console.log('Chat.js: Refreshing message with sessionToken:', sessionToken, 'and message:', lastUserMessage);

    try {
      const botResponseText = await fetchBotResponse(lastUserMessage, sessionToken); // Pass sessionToken

      // Start revealing words one by one with dynamic speed
      revealWords(newLatestBotMessageIndex, botResponseText);
    } catch (error) {
      console.error('Failed to fetch the bot response:', error);
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[newLatestBotMessageIndex] = {
          sender: 'bot',
          text: 'Sorry, something went wrong. Please try again.',
          isRevealing: false,
        };
        return newMessages;
      });
    }
  };

  // Toggle options panel
  const toggleOptions = () => {
    toggleOptionsOpen();
    setIsHovered(false);

    setTimeout(() => {
      if (chatRef.current && chatButtonRef.current) {
        const chatRect = chatRef.current.getBoundingClientRect();
        const chatButtonRect = chatButtonRef.current.getBoundingClientRect();

        const newChatPosition = {
          bottom: window.innerHeight - chatRect.bottom,
          right: window.innerWidth - chatRect.right,
        };
        setChatPosition(newChatPosition);
      }
    }, 300);
  };

  // Toggle Down component
  const toggleDown = () => {
    setIsDownOpen((prevIsDownOpen) => !prevIsDownOpen);
  };

  // Sync chat open state
  useEffect(() => {
    setIsChatOpen(isChatOpen);
  }, [isChatOpen, setIsChatOpen]);

  // Close Down component when options change
  useEffect(() => {
    setIsDownOpen(false);
  }, [isOptionsOpen]);

  // Handle edit click
  const handleEditClick = (index) => {
    setEditMode(true);
    setLatestUserMessageIndex(index);
    setOriginalMessage(messages[index].text);

    setTimeout(() => {
      if (textareaRef.current && chatRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;

        const chatContainerWidth = chatRef.current.offsetWidth;
        textareaRef.current.style.width = `${(chatContainerWidth * 3) / 4}px`;
      }
    }, 0);
  };

  // Handle save edit
  const handleSaveEdit = async () => {
    setEditMode(false);

    if (latestUserMessageIndex === null) return;

    const editedMessage = messages[latestUserMessageIndex].text;

    // Check if sessionToken is available before proceeding
    if (!sessionToken) {
      console.warn('Chat.js: sessionToken is not available, cannot save edited message.');
      // Optionally, inform the user or implement a retry mechanism here
      return;
    }

    const newMessages = messages.slice(0, latestBotMessageIndex);
    const loadingMessage = { sender: 'bot', text: 'loading', isRevealing: false };
    newMessages.push(loadingMessage);
    setMessages(newMessages);

    const newLatestBotMessageIndex = newMessages.length - 1;
    setLatestBotMessageIndex(newLatestBotMessageIndex);

    // Log before making the API call
    console.log('Chat.js: Saving edited message with sessionToken:', sessionToken, 'and message:', editedMessage);

    try {
      const botResponseText = await fetchBotResponse(editedMessage, sessionToken); // Pass sessionToken

      // Start revealing words one by one with dynamic speed
      revealWords(newLatestBotMessageIndex, botResponseText);
    } catch (error) {
      console.error('Failed to fetch the bot response:', error);
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[newLatestBotMessageIndex] = {
          sender: 'bot',
          text: 'Sorry, something went wrong. Please try again.',
          isRevealing: false,
        };
        return newMessages;
      });
    }
  };

  // Handle cancel edit
  const handleCancelEdit = () => {
    if (latestUserMessageIndex === null) return;

    setMessages((prevMessages) =>
      prevMessages.map((m, i) =>
        i === latestUserMessageIndex ? { ...m, text: originalMessage } : m
      )
    );
    setEditMode(false);
  };

  // Handle key down events
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (editMode) {
        handleSaveEdit();
      } else {
        handleSubmit(e);
      }
    }
  };

  // Clear all word reveal intervals on component unmount
  useEffect(() => {
    return () => {
      Object.values(wordRevealIntervals.current).forEach(clearInterval);
    };
  }, []);

  return (
    <>
      {isChatOpen && (
        <div
          className={`chat-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
          ref={chatRef}
          style={{
            position: isMobile ? 'absolute' : 'fixed',
            bottom: isMobile ? '0' : '0',
            left: isOptionsOpen ? '0' : '0',
            zIndex: zIndices.chat,
            transition: 'bottom 0.3s ease',
            width: '100%',
            height: isMobile ? (isOptionsOpen ? '96vh' : '100vh') : '100%',
            boxShadow: 'none',
            borderRadius: isMobile
              ? isOptionsOpen
                ? '2.5vh'
                : '25vh'
              : isOptionsOpen
              ? '0vw 0vh 0vh 0vh'
              : '0vw 0vh 0vh 0vh',
          }}
        >
          {/* Chat Header */}
          <div
            className="chat-header"
            style={{
              height: isMobile
                ? isOptionsOpen
                  ? '25vh'
                  : '10vh'
                : isOptionsOpen
                ? '25vh'
                : '19vh',
              background: isMobile
                ? isOptionsOpen
                  ? `linear-gradient(to bottom, ${backgroundColor} 60%, transparent 40%)`
                  : `transparent`
                : isOptionsOpen
                ? `linear-gradient(to bottom, ${backgroundColor} 60%, transparent 40%)`
                : `linear-gradient(to bottom, ${backgroundColor} 75%, transparent 25%)`,
              width: '100%',
              color: textColor,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: zIndices.chat + 2,
              fontSize: '1.5rem',
              borderRadius: isMobile
                ? '2.5vh'
                : isOptionsOpen
                ? '0vw 0vh 0vh 0vh'
                : '0vw 0vh 0vh 0vh',
              position: 'relative',
            }}
          >
            <Logo
              style={{
                position: isMobile ? 'fixed' : 'absolute',
                top: isMobile ? (isOptionsOpen ? '7.5vh' : '7vh') : '7.5vh',
                left: isMobile
                  ? isOptionsOpen
                    ? '1.5vmax'
                    : '7vh'
                  : isOptionsOpen
                  ? '1.5vmax'
                  : '1.5vmax',
                transform: 'translateX(-50%, -50%)',
                height: isMobile
                  ? isOptionsOpen
                    ? '8vmin'
                    : '32.5vh'
                  : '8vmin',
                width: isMobile
                  ? isOptionsOpen
                    ? '8vmin'
                    : '32.5vh'
                  : '8vmin',
                zIndex: zIndices.chat + 2,
                color: logoColor,
              }}
            />
            <div
              className="options-icon"
              onClick={toggleOptions}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              style={{
                position: isMobile ? 'fixed' : 'absolute',
                top: isMobile ? '5vh' : '8.5vh',
                right: isMobile ? '2.7vmax' : '2.7vmax',
                transform: 'translateX(-50%, -50%)',
                height: isMobile ? '4.5vmin' : '4.5vmin',
                width: isMobile ? '4.5vmin' : '4.5vmin',
                cursor: 'pointer',
                zIndex: zIndices.chat + 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: logoColor,
              }}
            >
              {isOptionsOpen ? (
                <MinusIcon
                  style={{
                    fill: 'currentColor',
                    width: '6vmin',
                    height: '6vmin',
                  }}
                />
              ) : (
                <PlusIcon
                  style={{
                    fill: 'currentColor',
                    width: '6vmin',
                    height: '6vmin',
                  }}
                />
              )}
            </div>

            {!(isMobile && !isOptionsOpen) && (
              <div
                className="down-icon"
                onClick={toggleDown}
                ref={chatButtonRef}
                style={{
                  position: 'absolute',
                  top: isMobile ? '5vh' : '7.55vh',
                  left: `calc(1.75vmax + 8vmin)`,
                  transform: 'translateX(-50%, -50%)',
                  cursor: 'pointer',
                  zIndex: zIndices.chat + 2,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: logoColor,
                }}
              >
                <DownIcon
                  style={{
                    fill: 'currentColor',
                    width: '7vmin',
                    height: '7vmin',
                  }}
                />
              </div>
            )}
          </div>

          {/* Down Component */}
          <Down isOpen={isDownOpen} chatRef={chatRef} buttonRef={chatButtonRef} />

          {/* Wave Background */}
          {!(isOptionsOpen === false && isMobile) && (
            <div
              className="wave-container"
              style={{
                position: 'absolute',
                top: '12.5vh',
                zIndex: zIndices.chat + 1,
              }}
            >
              <div
                className="wave"
                style={{
                  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='${waveColor}'/%3E%3C/svg%3E")`,
                  backgroundRepeat: 'repeat-x',
                }}
              ></div>
              <div
                className="wave"
                style={{
                  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='${waveColor}'/%3E%3C/svg%3E")`,
                  opacity: 0.5,
                  animationDuration: '15s',
                  backgroundRepeat: 'repeat-x',
                }}
              ></div>
              <div
                className="wave"
                style={{
                  background: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='${waveColor}'/%3E%3C/svg%3E")`,
                  opacity: 0.25,
                  animationDuration: '20s',
                  backgroundRepeat: 'repeat-x',
                }}
              ></div>
            </div>
          )}

          {/* Prompts List */}
          {messages.length === 0 && (
            <PromptsList
              setUserInput={setUserInput}
              setMessages={setMessages}
              setLatestBotMessageIndex={setLatestBotMessageIndex}
              setLatestUserMessageIndex={setLatestUserMessageIndex}
            />
          )}

          {/* Chat Window */}
          <div
            className="chat-window"
            ref={chatWindowRef}
            style={{
              display: isMobile && !isOptionsOpen ? 'none' : 'block',
              marginTop: isMobile
                ? isOptionsOpen
                  ? '0vh'
                  : '0vh'
                : isOptionsOpen
                ? '0vh'
                : '5.5vh',
            }}
          >
            {messages.map((msg, index) => (
              <div
                key={index}
                ref={(el) => (messageRefs.current[index] = el)}
                className={`chat-message ${msg.sender}`}
                style={{
                  marginLeft: isOptionsOpen
                    ? msg.sender === 'user'
                      ? 'auto'
                      : 'calc(9vh + 2.5vmax)'
                    : msg.sender === 'user'
                    ? 'auto'
                    : 'calc(2.5vh + 2.5em)',
                  marginRight: isOptionsOpen
                    ? msg.sender === 'user'
                      ? '0'
                      : 'auto'
                    : msg.sender === 'user'
                    ? '0'
                    : 'auto',
                  marginTop: index === 0 ? '10px' : '2vh',
                  marginBottom:
                    msg.sender === 'user'
                      ? '2vh'
                      : index === latestUserMessageIndex && isMultiline
                      ? '4vh'
                      : '4vh',
                  backgroundColor:
                    msg.sender === 'user'
                      ? primaryColor
                      : msg.text === 'loading'
                      ? 'transparent'
                      : botMessageColor,
                  color: msg.sender === 'user' ? textColor : isDarkMode ? '#f0f0f5' : '#1E1E1E',
                  fontSize: msg.sender === 'bot' ? '2vh' : '2vh',
                  fontFamily: msg.sender === 'bot' ? "'Helvetica Neue', sans-serif" : 'inherit',
                  minHeight: editMode && index === latestUserMessageIndex ? '8vh' : 'auto',
                  position: 'relative',
                  boxShadow:
                    msg.sender === 'user'
                      ? 'auto'
                      : msg.text === 'loading'
                      ? 'none' // Hide box shadow during loading
                      : isDarkMode
                      ? 'none'
                      : 'none',
                }}
              >
                {/* Bot profile picture */}
                {msg.sender === 'bot' && (
                  <div
                    className={`bot-avatar ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                    style={{
                      zIndex: zIndices.chat,
                      position: 'absolute',
                      top: '5vh',
                      left: '-10vh',
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'center',
                      width: '3.5em',
                      height: '3.5em',
                      borderRadius: '50%',
                      overflow: 'visible',
                    }}
                  >
                    <AthenaAnimation
                      isLoading={msg.text === 'loading'}
                      ref={athenaRef}
                      className="bot-avatar-icon"
                      style={{
                        padding: '0em',
                        marginRight: '1vh',
                        width: '5em',
                        height: '5em',
                        flexShrink: 0,
                        borderRadius: '50%',
                        top: '-1.75em',
                        position: 'relative',
                      }}
                    />
                  </div>
                )}

                {/* Conditionally Render Lottie Animation Over Bot Messages Being Revealed */}
                {msg.isRevealing && msg.sender === 'bot' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '125%',
                      transform: 'translate(-50%, -50%)',
                      zIndex: 10, // Ensure it's above the text
                      pointerEvents: 'none', // Allow clicks to pass through
                    }}
                  >
                    <LottieAnimation />
                  </div>
                )}

                {/* Edit Icon for User Messages */}
                {msg.sender === 'user' && index === latestUserMessageIndex && !editMode && (
                  <EditIcon
                    className={`edit-icon ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                    onClick={() => handleEditClick(index)}
                    style={{
                      width: '2.5vh',
                      height: '2.5vh',
                      position: 'absolute',
                      left: '-3vh',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      cursor: 'pointer',
                      color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                    }}
                  />
                )}

                {/* Message Content */}
                {msg.sender === 'user' && index === latestUserMessageIndex && editMode ? (
                  // Message Editor for editing messages
                  <>
                    <textarea
                      ref={textareaRef}
                      value={msg.text}
                      onChange={(e) => {
                        setMessages((prevMessages) =>
                          prevMessages.map((m, i) =>
                            i === index ? { ...m, text: e.target.value } : m
                          )
                        );
                        e.target.style.height = 'auto';
                        e.target.style.height = `${e.target.scrollHeight}px`;
                      }}
                      onKeyDown={handleKeyDown}
                      rows="1"
                      style={{
                        width: '100%',
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                        color: textColor,
                        marginBottom: '5px',
                        resize: 'none',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        whiteSpace: 'pre-wrap',
                        padding: '0.5vh 1.5vh',
                        borderRadius: '3vh',
                        lineHeight: '1.5',
                      }}
                    />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '1vh' }}>
                      <button
                        onClick={handleCancelEdit}
                        style={{
                          backgroundColor: isDarkMode ? '#1E1E1E' : '#f0f0f5',
                          color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                          borderRadius: '2vh',
                          padding: '1vh 2vh',
                          border: 'none',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          outline: 'none',
                          fontSize: '1.8vh',
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveEdit}
                        style={{
                          backgroundColor: isDarkMode ? '#1E1E1E' : '#0f0f0a',
                          color: isDarkMode ? '#0f0f0a' : '#1E1E1E',
                          borderRadius: '2vh',
                          padding: '1vh 2vh',
                          border: 'none',
                          cursor: 'pointer',
                          boxShadow: 'none',
                          outline: 'none',
                          fontSize: '1.8vh',
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {msg.sender === 'bot' && msg.text !== 'loading' && index === latestBotMessageIndex && (
                      <>
                        <RefreshIcon
                          className={`refresh-icon ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                          onClick={handleRefresh}
                          style={{
                            cursor: 'pointer',
                            width: '2.25vh',
                            height: '2.25vh',
                            position: 'absolute',
                            bottom: '-4.5vh',
                            left: '1vh',
                            transform: 'translateY(-50%)',
                            color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                          }}
                        />
                        <ThumbUpIcon
                          className={`thumb-up-icon ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                          style={{
                            cursor: 'pointer',
                            width: '2.5vh',
                            height: '2.5vh',
                            position: 'absolute',
                            bottom: '-4.7vh',
                            left: '4vh',
                            transform: 'translateY(-50%)',
                            color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                          }}
                        />
                        <ThumbDownIcon
                          className={`thumb-down-icon ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
                          style={{
                            cursor: 'pointer',
                            width: '2.5vh',
                            height: '2.5vh',
                            position: 'absolute',
                            bottom: '-4.7vh',
                            left: '7vh',
                            transform: 'translateY(-50%)',
                            color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                          }}
                        />
                      </>
                    )}
                    {msg.text !== 'loading' && msg.text}
                  </>
                )}
              </div>
            ))}
          </div>

          {/* Chat Input */}
          <div
            className="chat-input-wrapper"
            style={{
              marginLeft: isMobile
                ? isOptionsOpen
                  ? 'calc(1.5vmax + 1.25vh)'
                  : '7.5vh'
                : 'calc(1.5vmax + 1.25vh)',
              marginRight: isMobile
                ? isOptionsOpen
                  ? '2.5vmax'
                  : '5vh'
                : '2.5vmax',
              marginBottom: isMobile
                ? isOptionsOpen
                  ? '3.5vh'
                  : '7.5vh'
                : '3.5vh',
              width: isMobile
                ? isOptionsOpen
                  ? 'calc(100% - 4vmax - 1.25vh)'
                  : 'calc(100% - 15vh)'
                : 'calc(100% - 4vmax - 1.25vh)',
              height: isMobile ? (isOptionsOpen ? 'unset' : '50vh') : 'unset',
              boxSizing: 'border-box',
              borderRadius: isMobile
                ? isOptionsOpen
                  ? '2.5vh'
                  : '25vh'
                : '2.5vh',
              backgroundColor: isDarkMode ? '#1E1E1E' : '#ffffff',
            }}
          >
            <form
              key={`${isDarkMode}-${primaryColor}`}
              onSubmit={handleSubmit}
              className="chat-input"
              style={{
                border: `1.5px solid ${isDarkMode ? '#1E1E1E' : '#ffffff'}`,
                borderRadius: '2em',
                width: '100%',
                boxSizing: 'border-box',
              }}
            >
              <textarea
                ref={textareaRef}
                value={userInput}
                onChange={(e) => {
                  setUserInput(e.target.value);
                  e.target.style.height = 'auto';
                  e.target.style.height = `${e.target.scrollHeight}px`;
                }}
                onKeyDown={handleKeyDown}
                placeholder="Message Athena"
                rows="1"
                style={{
                  width: '100%',
                  resize: 'none',
                  overflow: 'hidden',
                  overflowY: 'auto',
                  whiteSpace: 'pre-wrap',
                  backgroundColor: 'transparent',
                  color: isDarkMode ? '#f0f0f5' : '#1E1E1E',
                  border: 'none',
                  outline: 'none',
                  flexGrow: 1,
                  padding: '0.5vh 1.5vh',
                  borderRadius: '3vh',
                  lineHeight: '1.5',
                }}
              />

              <SendButton userInput={userInput} />
            </form>
          </div>
        </div>
      )}

      {/* Options Component */}
      {isChatOpen && (
        <>
          <Options
            isOptionsOpen={isOptionsOpen}
            toggleOptions={toggleOptions}
            chatPosition={chatPosition}
            chatButtonRealTop={storedChatButtonTop}
            chatButtonRealBottom={storedChatButtonBottom}
            chatButtonRealLeft={storedChatButtonLeft}
            chatButtonRealRight={storedChatButtonRight}
            chatButtonRealWidth={storedChatButtonWidth}
            chatButtonRealHeight={chatRealHeight}
            chatWidth={chatWidth}
            updateOptionsDimensions={handleUpdateOptionsDimensions}
            chatRealBottom={chatRealBottom}
            chatRealRight={chatRealRight}
            chatRealWidth={chatRealWidth}
            chatRealHeight={chatRealHeight}
            buttonRef={chatButtonRef}
          />
        </>
      )}

      {/* Hidden Chat Button Reference */}
      <div
        ref={chatButtonRef}
        style={{ position: 'fixed', bottom: '20px', right: '20px', opacity: 0 }}
      />
    </>
  );
};

export default Chat;
