import React, { useContext, useEffect, useState, useRef } from 'react';
import { ThemeContext } from './ThemeContext.js';
import '@radial-color-picker/react-color-picker/dist/style.css';
import ColorPicker from '@radial-color-picker/react-color-picker';
import './Settings.css';
import logo from './logo.svg';
import { ReactComponent as SunIcon } from './sun.svg';
import { ReactComponent as MoonIcon } from './moon.svg';
import anime from 'animejs/lib/anime.es.js';
import { adjustTextColor, getBackgroundColor } from './utils.js';

// Helper functions
const hexToHue = (hex) => {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let hue = 0;

  if (max === min) {
    hue = 0;
  } else if (max === r) {
    hue = ((g - b) / (max - min)) * 60;
  } else if (max === g) {
    hue = (2.0 + (b - r) / (max - min)) * 60;
  } else {
    hue = (4.0 + (r - g) / (max - min)) * 60;
  }

  if (hue < 0) hue += 360;
  return hue;
};

const hueToHex = (hue) => {
  const s = 1;
  const l = 0.5;
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const x = c * (1 - Math.abs((hue / 60) % 2 - 1));
  const m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;

  if (hue >= 0 && hue < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (hue >= 60 && hue < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (hue >= 120 && hue < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (hue >= 180 && hue < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (hue >= 240 && hue < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (hue >= 300 && hue < 360) {
    r = c;
    g = 0;
    b = x;
  }

  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);

  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const Settings = ({
  ChatBottom,
  OptionsRealRight,
  OptionsRealWidth,
  OptionsRealHeight,
  chatRealBottom,
  chatRealRight,
  chatRealWidth,
  chatRealHeight,
  distance,
  settingsButtonRef, // Added to check if the click is on the settings button
}) => {
  const {
    isDarkMode,
    toggleTheme,
    primaryColor,
    updatePrimaryColor,
    resetPrimaryColor,
    zIndices,
    toggleSettingsOpen,
  } = useContext(ThemeContext);

  const [pickerSize, setPickerSize] = useState(null);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [brightness, setBrightness] = useState(100);
  const [isSunHovered, setIsSunHovered] = useState(false);
  const [isMoonHovered, setIsMoonHovered] = useState(false);
  const containerRef = useRef(null);
  const resetButtonRef = useRef(null);
  const brightnessRef = useRef(null);
  const clickTimerRef = useRef(null);
  const [position, setPosition] = useState({ top: '0px', left: '0px' });

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const hueColor = hexToHue(primaryColor);
  const backgroundColor = getBackgroundColor(primaryColor, isDarkMode);


  // Dynamically set the left value based on the settings button's position
  useEffect(() => {
    if (settingsButtonRef.current) {
      const buttonRect = settingsButtonRef.current.getBoundingClientRect();
      setPosition({
        left: `${buttonRect.left}px`,  // Set the left position based on the button's left
      });
    }
  }, [settingsButtonRef]);

  // Handlers for hover effects
  const handleSunMouseEnter = () => setIsSunHovered(true);
  const handleSunMouseLeave = () => setIsSunHovered(false);
  const handleMoonMouseEnter = () => setIsMoonHovered(true);
  const handleMoonMouseLeave = () => setIsMoonHovered(false);

  // Handlers for brightness control
  const handleBrightnessChange = (yPos) => {
    const { top, height } = brightnessRef.current.getBoundingClientRect();
    const newY = yPos - top;
    const newBrightness = 100 - Math.min(100, Math.max(0, (newY / height) * 100));
    setBrightness(newBrightness);
    document.documentElement.style.setProperty('--brightness', newBrightness / 100);
  };

  const handleBrightnessDrag = (e) => {
    document.addEventListener('mousemove', handleMouseBrightnessChange);
    document.addEventListener(
      'mouseup',
      () => {
        document.removeEventListener('mousemove', handleMouseBrightnessChange);
      },
      { once: true }
    );
  };

  const handleMouseBrightnessChange = (e) => {
    handleBrightnessChange(e.clientY);
  };

  const handleTouchBrightnessChange = (e) => {
    if (e.touches && e.touches.length > 0) {
      handleBrightnessChange(e.touches[0].clientY);
    }
  };

  const handleTouchStart = (e) => {
    handleTouchBrightnessChange(e);
    document.addEventListener('touchmove', handleTouchBrightnessChange);
    document.addEventListener(
      'touchend',
      () => {
        document.removeEventListener('touchmove', handleTouchBrightnessChange);
      },
      { once: true }
    );
  };

  // Calculate picker sizes on mount and resize
  useEffect(() => {
    const calculateSizes = () => {
      if (containerRef.current) {
        const { height, width } = containerRef.current.getBoundingClientRect();
        const buttonSize = Math.max(height, width) * (14 / 16); // Ensure square size
        setPickerSize(buttonSize);
      }
    };

    calculateSizes();
    window.addEventListener('resize', calculateSizes);

    return () => {
      window.removeEventListener('resize', calculateSizes);
    };
  }, []);

  // Apply brightness filter
  useEffect(() => {
    document.documentElement.style.filter = `brightness(${brightness}%)`;
  }, [brightness]);

  // Handle color changes from the color picker
  const handleColorChange = (color) => {
    const hexColor = hueToHex(color);
    updatePrimaryColor(hexColor);
    handleResetColor();
    setTimeout(() => {
      handleResetColor();
    }, 100);
  };

  // Handle reset color button clicks
  const handleResetColor = () => {
    if (clickTimerRef.current) {
      clearTimeout(clickTimerRef.current);
      clickTimerRef.current = null;

      const colorPickerWell = document.querySelector('.rcp__well');
      if (colorPickerWell) {
        colorPickerWell.click();
        setIsEnlarged(!isEnlarged);
        anime({
          targets: resetButtonRef.current,
          scale: isEnlarged ? 1 : 2.0,
          duration: 300,
          easing: 'easeInOutQuad',
          delay: 500,
        });
      }
    } else {
      clickTimerRef.current = setTimeout(() => {
        resetPrimaryColor();
        clickTimerRef.current = null;
      }, 1000);
    }
  };

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Ensure click is not on settings panel or settings button
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        settingsButtonRef.current &&
        !settingsButtonRef.current.contains(event.target)
      ) {
        toggleSettingsOpen(false); // Close settings via ThemeContext
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toggleSettingsOpen, settingsButtonRef]); // Include settingsButtonRef as a dependency

  return (
        <div
        style={{
            position: 'absolute',
            top: isMobile ? 'calc(9vh + 4.5vmin)' : 'calc(9vh + 4.5vmin)',
            left: position.left,//'calc(1.5vmax + 21vmin + 2.5vh)',//'2.5vh',
            transform: 'scale(0.5)',
            transformOrigin: 'top left',
            zIndex: zIndices.settings,
        }}
        >
      <div
        className={`settings-overlay ${isDarkMode ? 'dark-mode' : 'light-mode'}`}
        style={{
          //position: 'absolute',
          bottom: isMobile ? '10vh' : '10vh',
          right: isMobile ? '0px' : '10vw',
          transition: 'bottom 0.3s ease',
          width: isMobile ? '100vw' : '40vh',
          height: isMobile ? '90vh' : '50vh',
          borderRadius: isMobile ? '0' : '3vh',
          overflow: isMobile ? 'hidden' : 'visible',
        }}
      >
        <div
          className="settings-container"
          ref={containerRef}
          style={{
            backdropFilter: 'none',
            background: backgroundColor,
            borderRadius: isMobile ? '0' : '3vh',
            position: 'relative',
          }}
        >
          {/* Color Picker */}
          <div
            className="color-picker-container"
            style={{
              position: 'absolute',
              top: '5%',
              left: '5%',
              width: `${pickerSize * 0.9}px`,
              height: `${pickerSize * 0.9}px`,
              boxSizing: 'border-box',
            }}
          >
            <ColorPicker color={hueColor} onChange={handleColorChange} />
            <button
              ref={resetButtonRef}
              className="reset-button"
              onClick={handleResetColor}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                height: '50%',
                borderRadius: '50%',
                padding: '0',
                border: 'none',
                cursor: 'pointer',
                zIndex: 2,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            >
              <img
                src={logo}
                style={{
                  width: '100%',
                  height: '100%',
                  padding: '15%',
                  filter: 'none',
                }}
                alt="Reset"
              />
            </button>
          </div>

          {/* Theme Toggle Button */}
          <div
            className="theme-button-container"
            style={{
              position: 'absolute',
              bottom: '5%',
              left: '5%',
              width: `${pickerSize * 1}px`,
              height: `${pickerSize * 1}px`,
              boxSizing: 'border-box',
            }}
          >
            <button
              className="icon-button"
              onClick={toggleTheme}
              onMouseEnter={isDarkMode ? handleSunMouseEnter : handleMoonMouseEnter}
              onMouseLeave={isDarkMode ? handleSunMouseLeave : handleMoonMouseLeave}
              style={{
                width: '100%',
                height: '100%',
                padding: '5px',
                border: 'none',
                cursor: 'pointer',
                zIndex: 3,
                overflow: 'hidden',
                color: isDarkMode
                  ? isSunHovered
                    ? '#ffffaa'
                    : '#f0f0f5'
                  : isMoonHovered
                  ? '#aaaaff'
                  : '#f0f0f5',
              }}
            >
              {isDarkMode ? (
                <SunIcon
                  className={`sun-icon ${isSunHovered ? 'hover' : ''}`}
                  style={{ width: '100%', height: '100%' }}
                />
              ) : (
                <MoonIcon
                  className={`moon-icon ${isMoonHovered ? 'hover' : ''}`}
                  style={{ width: '100%', height: '100%' }}
                />
              )}
            </button>
          </div>

          {/* Brightness Control */}
          <div
            className="brightness-control-container"
            ref={brightnessRef}
            style={{
              position: 'absolute',
              top: '5%',
              right: '5%',
              width: '25%',
              height: '90%',
              background: isDarkMode
                ? 'rgba(42, 42, 42, 1)'
                : 'rgba(40, 40, 40, 0.5)',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 4,
            }}
            onMouseDown={handleBrightnessDrag}
            onTouchStart={handleTouchStart}
          >
            <div
              className="brightness-control"
              style={{
                width: '100%',
                height: '100%',
                background: `
                  linear-gradient(to top, 
                  ${isDarkMode ? '#f0f0f5' : '#f0f0f5'} ${brightness}%, 
                  ${
                    isDarkMode
                      ? 'rgba(42, 42, 42, 0.75)'
                      : 'rgba(40, 40, 40, 0.3)'
                  } ${brightness}%),
                  ${
                    isDarkMode
                      ? 'rgba(42, 42, 42, 0.75)'
                      : 'rgba(255, 255, 255, 0.25)'
                  }
                `,
                position: 'relative',
              }}
            >
              <div
                className="brightness-thumb"
                style={{
                  position: 'absolute',
                  left: '0',
                  width: '100%',
                  top: `${100 - brightness}%`,
                  height: '2px',
                  backgroundColor: 'transparent',//isDarkMode ? '#f0f0f5' : '#f0f0f5',
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
