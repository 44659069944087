// Prompts.js

import React, { useContext, useRef, useEffect, useState } from 'react';
import Slider from 'react-slick'; // Import react-slick for the carousel/slider
import { ThemeContext } from './ThemeContext'; // Import ThemeContext
import { ReactComponent as Logo } from './logo.svg'; // Example logo
import { ReactComponent as PlusIcon } from './plus.svg';
import { ReactComponent as MinusIcon } from './minus.svg';
import 'slick-carousel/slick/slick.css'; // Slick carousel styles
import 'slick-carousel/slick/slick-theme.css'; // Slick carousel theme
import anime from 'animejs/lib/anime.es.js'; // Import anime.js for animations

// Custom hook to detect window size changes
const useMobileViewport = (breakpoint = 750) => {
  const [isMobileViewport, setIsMobileViewport] = useState(window.innerWidth <= breakpoint);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileViewport(window.innerWidth <= breakpoint);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [breakpoint]);

  return isMobileViewport;
};

// Mobile detection function
const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

// List of prompts with associated text and image
export const prompts = [
  {
    text: 'Get to know Athena',
    image: <Logo style={{ marginTop: '2vh', marginLeft: '0', width: '10vh', height: '10vh' }} />,
  },
  {
    text: 'Setup profile',
    image: <Logo style={{ marginTop: '2vh', marginLeft: '0', width: '10vh', height: '10vh' }} />,
  },
  {
    text: 'What size',
    image: <Logo style={{ marginTop: '2vh', marginLeft: '0', width: '10vh', height: '10vh' }} />,
  },
  {
    text: 'Recent Drops',
    image: <Logo style={{ marginTop: '2vh', marginLeft: '0', width: '10vh', height: '10vh' }} />,
  },
  {
    text: 'Explain closures',
    image: <Logo style={{ marginTop: '2vh', marginLeft: '0', width: '10vh', height: '10vh' }} />,
  },
];

// Custom arrow for previous slide
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        left: '10vw',
        zIndex: 1000,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <MinusIcon style={{ fill: 'currentColor', width: '4.5vh', height: '4.5vh' }} />
    </div>
  );
};

// Custom arrow for next slide
const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        right: '10vw',
        zIndex: 1000,
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <PlusIcon style={{ fill: 'currentColor', width: '4.5vh', height: '4.5vh' }} />
    </div>
  );
};

export const PromptsList = ({
  setUserInput,
  setMessages,
  setLatestBotMessageIndex,
  setLatestUserMessageIndex, // Added prop
}) => {
  const { primaryColor, textColor, isDarkMode, isOptionsOpen } = useContext(ThemeContext); // Access theme context
  const [currentSlide, setCurrentSlide] = useState(0); // State to track current slide
  const sliderRef = useRef(null); // Reference to the slider

  // Mobile detection using both user agent and viewport width
  const isMobile = useMobileViewport() || isMobileDevice();

  // The rest of the component logic (slider, click handler, etc.)
  const handlePromptClick = async (promptText) => {
    setUserInput(promptText); // Set prompt text to input field
    const userMessage = { sender: 'user', text: promptText };

    // Add user message and set latestUserMessageIndex
    setMessages((prevMessages) => {
      const newMessages = [...prevMessages, userMessage];
      setLatestUserMessageIndex(newMessages.length - 1); // Set the index for edit functionality
      return newMessages;
    });
    setUserInput('');

    // Simulate API call to handle prompt as input
    try {
      const response = await fetch('http://localhost:5000/api/gpt/generate', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: promptText }),
      });

      if (!response.ok) throw new Error('Network response was not ok');

      const data = await response.json();
      const botMessage = { sender: 'bot', text: data.response };

      // Add bot message and set latestBotMessageIndex
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, botMessage];
        setLatestBotMessageIndex(newMessages.length - 1); // Set the index for refresh functionality
        return newMessages;
      });
    } catch (error) {
      console.error('Failed to fetch the bot response:', error);
    }
  };

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0px',
    prevArrow: <CustomPrevArrow />, // Using the custom arrows
    nextArrow: <CustomNextArrow />,
    swipe: false,
    draggable: false,
    afterChange: (index) => setCurrentSlide(index),
  };

  // Function to apply anime.js animations to slides
  const applyAnimations = () => {
    anime({
      targets: '.slick-slide',
      scale: 0.85, // Default scale for all slides
      duration: 500,
      easing: 'easeOutQuad',
    });
    anime({
      targets: '.slick-center',
      scale: 1.3, // Larger scale for center slide
      duration: 500,
      easing: 'easeOutQuad',
    });
  };

  useEffect(() => {
    applyAnimations(); // Apply animations on render

    const interval = setInterval(() => {
      applyAnimations(); // Periodically reapply animations
    }, 300); // Adjust interval as needed

    return () => clearInterval(interval); // Cleanup on unmount
  }, [isOptionsOpen]); // Rerun effect if slider changes

  return (
    <>
      {!(isMobile && !isOptionsOpen) && (
        <Slider {...sliderSettings} ref={sliderRef}>
          {prompts.map((prompt, index) => (
            <div
              key={index}
              className="slick-slide"
              style={{
                textAlign: 'center',
                width: '100%',
                marginRight: '2vh',
                transition: 'transform 0.3s ease-in-out',
              }}
            >
              <div
                style={{
                  marginBottom: '1vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {React.cloneElement(prompt.image, {
                  style: {
                    ...prompt.image.props.style,
                    marginTop: isOptionsOpen ? '20vh' : '2vh',
                    width: isOptionsOpen ? '20vh' : '10vh',
                    height: isOptionsOpen ? '20vh' : '10vh',
                  },
                })}
              </div>
              <button
                className="prompt-box"
                onClick={() => handlePromptClick(prompt.text)}
                style={{
                  marginLeft: isOptionsOpen ? '4vh' : '2vh',
                  width: '15vh',
                  height: '10vh',
                  padding: '1vh',
                  borderRadius: '1vh',
                  backgroundColor: primaryColor, // Use theme color
                  color: textColor, // Use theme text color
                  border: 'none',
                  cursor: 'pointer',
                  fontSize: '2vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {prompt.text}
              </button>
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};
