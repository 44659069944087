import React, { useEffect, useContext } from 'react';
import './FlappyBird.css';
import { ThemeContext } from './ThemeContext.js';

const FlappyBird = ({ onClose }) => {
    const { zIndices } = useContext(ThemeContext);

    useEffect(() => {
        console.log('FlappyBird component has mounted.');
        console.time("FlappyBird Loading Time");

        // Calculate dynamic dimensions based on viewport height and the 280:420 aspect ratio
        const desiredHeightVh = 70; // Use 70% of the viewport height, adjust as needed
        const viewportHeight = window.innerHeight;
        const heightPx = (desiredHeightVh / 100) * viewportHeight; // Convert vh to px
        const widthPx = (heightPx * (280 / 420)); // Maintain the aspect ratio of 280:420

        // Create a new container for the iframe
        const container = document.createElement('div');
        container.id = 'flappyBirdContainer';
        container.style.position = 'fixed';
        container.style.top = '50%';
        container.style.left = '50%';
        container.style.width = `${widthPx}px`;
        container.style.height = `${heightPx}px`;
        container.style.zIndex = zIndices.flappyBird;
        container.style.borderRadius = '0';
        container.style.overflow = 'hidden';
        container.style.transform = 'translate(-50%, -50%)'; // Center the container

        document.body.appendChild(container);

        // Add iframe to the container
        const iframe = document.createElement('iframe');
        iframe.src = "/JS-Flappy-Bird/index.html";
        iframe.style.width = '100%';
        iframe.style.height = '100%';
        iframe.style.border = 'none';
        iframe.style.backgroundColor = 'transparent'; // Make background transparent
        iframe.allow = "autoplay"; // Allow autoplay if needed
        iframe.sandbox = "allow-scripts";
        container.appendChild(iframe);

        console.log("Iframe added to the container.");
        console.timeEnd("FlappyBird Loading Time");

        // Set up message event listener
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return;
            if (event.data === 'closeFlappyBird') {
                onClose();
            }
        };

        window.addEventListener('message', handleMessage);

        // Event listener to close on click outside
        const handleClickOutside = (event) => {
            if (!container.contains(event.target)) {
                onClose(); // Close if clicked outside the container
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            console.log("FlappyBird component is unmounting.");
            window.removeEventListener('message', handleMessage);
            document.removeEventListener('mousedown', handleClickOutside);
            document.body.removeChild(container);
        };
    }, [onClose, zIndices.flappyBird]);

    return null;
};

export default FlappyBird;
